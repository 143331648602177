/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React from 'react';
import App from 'next/app';
import { gql } from '@apollo/client';
import Cookies from 'js-cookie';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import routeMiddleware from '@middleware_route';

import graphRequest from '@graphql_request';
import graphRequestPim from '@graphql_request_pim';
import graphRequestInternal from '@graphql_request_internal';

import theme from '@theme_theme';
import { appWithTranslation } from '@i18n';
import { features, multipleServices } from '@config';

import { getAppEnv } from '@helper_env';
import { setResolver } from '@helper_localstorage';
import { getLoginInfo, getLastPathWithoutLogin } from '@helper_auth';

import LinearProgress from '@common_loaders/PageProgress';

import Error from '@modules/error/pages/default';
import SellerOrderPage from '@sellermodules/order/pages/list';
import UserEdit from '@modules/dashboard/pages/edit';
import UserEditSeller from '@sellermodules/manageaccount/pages/default';
import { storeConfig as ConfigSchema, storeConfigPim as ConfigPimSchema } from '@services/graphql/schema/config';
import { getStoreLogo } from '@services/graphql/schema/storeLogo';
import { storeConfigVar, storeConfigPimVar, storeLogoVar } from '@services/graphql/cache';

/**
 * Uncomment codes below when firebase push notification configuration is enabled
 * */
import Notification from '@lib_firebase/notification';
import firebase from '@lib_firebase/index';

class MyApp extends App {
    constructor(props) {
        super(props);
        this.isLogin = false;
    }

    static async getInitialProps(appContex) {
        const { Component, ctx } = appContex;
        let { pageProps } = await App.getInitialProps(appContex);

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }
        const {
            res, pathname, query, req,
        } = ctx;
        // check if login from server
        let isLogin = 0;
        let lastPathNoAuth = '';
        const allcookie = req ? req.cookies : {};
        let isSeller = false;
        if (typeof window !== 'undefined') {
            isLogin = getLoginInfo();
            lastPathNoAuth = getLastPathWithoutLogin();
            isSeller = Cookies.getJSON('cdt')?.customer_company_code;
        } else {
            isSeller = !!JSON.parse(allcookie?.cdt || '{}')?.customer_company_code;
            isLogin = allcookie.isLogin || 0;
            lastPathNoAuth = req && req.session && req.session.lastPathNoAuth ? req.session.lastPathNoAuth : isSeller ? '/seller/order/orderlist' : '/';
        }
        isLogin = parseInt(isLogin);
        routeMiddleware({
            res, req, query, asPath: pathname, isLogin, lastPathNoAuth, isSeller,
        });

        let { storeConfig, storeConfigPim, storeLogo } = pageProps;
        const isObjectEmpty = (obj) => !obj || obj === '' || (typeof obj === 'object' && Object.keys(obj).length === 0);

        // add get session from server
        if (typeof window === 'undefined' && isObjectEmpty(storeLogo)) {
            storeLogo = await graphRequestInternal(getStoreLogo);
            storeLogo = storeLogo.getStoreLogo;
        } else if (typeof window !== 'undefined' && isObjectEmpty(storeLogo)) {
            storeLogo = storeLogoVar();
            if (isObjectEmpty(storeLogo)) {
                storeLogo = await graphRequestInternal(getStoreLogo);
                storeLogo = storeLogo?.getStoreLogo || {};
            }
        }

        if (typeof window === 'undefined' && isObjectEmpty(storeConfig)) {
            storeConfig = await graphRequest(ConfigSchema);
            storeConfig = storeConfig?.storeConfig || {};
        } else if (typeof window !== 'undefined' && isObjectEmpty(storeConfig)) {
            storeConfig = storeConfigVar();
            if (isObjectEmpty(storeConfig)) {
                storeConfig = await pageProps.apolloClient
                    .query({
                        query: gql`
                            ${ConfigSchema}
                        `,
                    })
                    .then(({ data }) => data);
                storeConfig = storeConfig?.storeConfig || {};
            }
        }

        if (multipleServices.pim) {
            if (typeof window === 'undefined' && isObjectEmpty(storeConfigPim)) {
                storeConfigPim = await graphRequestPim(ConfigPimSchema);
                storeConfigPim = storeConfigPim?.storeConfig || {};
            } else if (typeof window !== 'undefined' && isObjectEmpty(storeConfigPim)) {
                storeConfigPim = storeConfigPimVar();
                if (isObjectEmpty(storeConfigPim)) {
                    storeConfigPim = await graphRequestPim(ConfigPimSchema);
                    storeConfigPim = storeConfigPim?.storeConfig || {};
                }
            }
        }
        return {
            pageProps: {
                ...pageProps, isLogin, lastPathNoAuth, storeLogo, isSeller, storeConfig, storeConfigPim,
            },
        };
    }

    componentDidMount() {
        /*
         * ---------------------------------------------
         * REMOVE CONSOLE
         * remove all console.log statement when APP_ENV = 'prod'
         */
        if (getAppEnv() === 'prod') {
            // eslint-disable-next-line no-console
            console.log = () => { };
        }

        /*
         * ---------------------------------------------
         * FIREBASE INITIALIZATION
         */
        const firebaseApiKey = String(process.env.FIREBASE_API_KEY);

        if (firebaseApiKey !== '' && features.pushNotification.enabled) {
            // initial firebase messaging
            Notification.init();
            // handle if have message on focus
            try {
                const messaging = firebase.messaging();
                // Handle incoming messages. Called when:
                // - a message is received while the app has focus
                // - the user clicks on an app notification created by a service worker
                //   `messaging.setBackgroundMessageHandler` handler.
                messaging.onMessage((payload) => {
                    navigator.serviceWorker.ready.then((registration) => {
                        // This prevents to show one notification for each tab
                        setTimeout(() => {
                            // eslint-disable-next-line no-console
                            console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
                            const lastNotification = localStorage.getItem('lastNotification');
                            const isDifferentContent = payload.data.updated_date + payload.data.title !== lastNotification;
                            if (isDifferentContent) {
                                localStorage.setItem('lastNotification', payload.data.updated_date + payload.data.title);
                                registration.showNotification(payload.data.title, {
                                    body: payload.data.body,
                                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                                    icon: payload.data.logo || '',
                                    image: payload.data.image || '',
                                    data: payload.data,
                                });
                            }
                        }, Math.random() * 1000);
                    });
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            }
        }

        /*
         * LAZY LOADING FONTS
         * Use this to load non critical fonts
         */
        // Fonts();

        /*
         * ---------------------------------------------
         * REMOVE THE SERVER SIDE INJECTED CSS
         * This is for speed performanc purpose
         */
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        /*
         * ---------------------------------------------
         * COOKIE CLEARANCE
         * remove config cookie if the page is reloaded
         */
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line func-names
            window.onbeforeunload = function () {
                setResolver({});
            };
        }
    }

    render() {
        const { Component, pageProps, router } = this.props;
        if (typeof document !== 'undefined') {
            const storeLogo = typeof pageProps.storeLogo === 'string' ? JSON.parse(pageProps.storeLogo) : pageProps.storeLogo || {};
            pageProps.storeLogo = storeLogo;
            // will run in client's browser only
            const favEl = document.getElementById('favicon');
            favEl.href = storeLogo?.favicon || '/assets/img/swiftoms_logo_collapsed.png';
            storeConfigVar(pageProps.storeConfig);
            storeConfigPimVar(pageProps.storeConfigPim);
            storeLogoVar(pageProps.storeLogo);
        }
        const forceResetPassword = Cookies.getJSON('cdt')?.force_reset_password;
        const { isSeller } = pageProps;
        let allowed = true;

        const whitelist = ['/version', '/requestreturn', '/user/account/confirm', '/seller-registration-tnc', '/seller-registration-privacy-policy'];
        if (!whitelist.some((rt) => router.asPath.startsWith(rt))) {
            if (pageProps.isLogin && isSeller && !router.asPath.startsWith('/seller/')) {
                allowed = false;
            }

            if (pageProps.isLogin && !isSeller && router.asPath.startsWith('/seller/')) {
                allowed = false;
            }
        }

        if (forceResetPassword && pageProps.isLogin && (router.asPath !== (isSeller ? '/seller/account' : '/useredit')) && typeof window !== 'undefined') {
            router.replace(isSeller ? '/seller/account' : '/useredit');
        }

        const renderComponent = () => {
            if (forceResetPassword && pageProps.isLogin) {
                if (isSeller) {
                    return <UserEditSeller {...pageProps} />;
                }
                return <UserEdit {...pageProps} />;
            } if (allowed) {
                return <Component {...pageProps} />;
            } if (isSeller && router.route === '/') {
                return <SellerOrderPage {...pageProps} />;
            }
            return <Error {...pageProps} statusCode={404} isSeller={isSeller} />;
        };

        return (
            <>
                <LinearProgress />
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    {/* <PageProgressLoader /> */}
                    {renderComponent()}
                </ThemeProvider>
            </>
        );
    }
}
export default (appWithTranslation(MyApp));
